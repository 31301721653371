export default defineNuxtRouteMiddleware(async (to, from) => {
  const { firstMainCategory, isLoading } = storeToRefs(useCasinoStore())

  /**
   * Миддлварам не нравится когда платформа определяется через useRoute, поэтому тут ее явно прокинул через параметры. На будущее нужно перейти на provide/inject
   * */
  const prefixRoute = usePrefixRoute({
    isMobile: from.fullPath.includes('mobile'),
  })

  await until(isLoading).toBe(false)

  if (!firstMainCategory.value) return navigateTo(prefixRoute('/'))

  return navigateTo({
    path: prefixRoute(
      `/casino/categories/${firstMainCategory.value.code}`,
    ) as string,
    query: to.query,
  })
})
